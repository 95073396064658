import React, { useState, useEffect } from 'react';
import sanityClient from '../../sanity'; // Import Sanity client

const JobCard = ({ category, title, description, imageUrl, slug }) => (
  <div className="flex flex-col md:flex-row bg-white rounded-3xl overflow-hidden shadow-lg w-full h-auto md:h-[300px]">
    <div className="w-full md:w-1/2 h-[200px] md:h-full relative">
      <img src={imageUrl} alt={title} className="w-full h-full object-cover object-center absolute inset-0" />
    </div>
    <div className="w-full md:w-1/2 p-6 flex flex-col justify-between">
      <div>
        <p className="text-red-600 text-lg md:text-xl mb-2 text-left">{category}</p>
        <h3 className="font-bold text-xl md:text-2xl mb-4 text-left">{title}</h3>
        <p className="text-gray-600 text-sm md:text-base text-left overflow-hidden line-clamp-3 mb-4">{description}</p>
      </div>
      <button className="text-red-600 text-lg md:text-xl bg-transparent border-none cursor-pointer p-0 text-left self-start">Read More</button>
    </div>
  </div>
);

const ClosedCallsProject = () => {
  const [closedCalls, setClosedCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClosedCalls = async () => {
      try {
        const data = await sanityClient.fetch(
          `*[_type == "projectsPost" && category == "closed"]{
            title,
            slug,
            mainImage{
              asset->{
                _id,
                url
              },
              alt
            },
            body,
            metaDescription,
            "excerpt": pt::text(body)[0...200]
          }`
        );
        setClosedCalls(data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching closed calls:", err);
        setError("Failed to load closed calls. Please try again later.");
        setLoading(false);
      }
    };

    fetchClosedCalls();
  }, []);

  if (loading) return <div className="text-center py-8 text-xl">Loading...</div>;
  if (error) return <div className="text-center py-8 text-xl text-red-600">{error}</div>;

  return (
    <div className="bg-gray-100 p-4 md:p-8 bg-[url('/public/Asset 3.png')] bg-[length:150px_150px] md:bg-[length:300px_300px] bg-top bg-no-repeat bg-origin-content pt-20 md:pt-32">
      <div className="text-center mb-6 md:mb-8">
        <h2 className="text-gray-600 text-lg md:text-xl mb-2">Explore Our Closed Investment Opportunities</h2>
        <h1 className="text-red-600 text-3xl md:text-4xl font-bold">These Calls Have Ended</h1>
        <div className="w-16 md:w-20 h-1 bg-red-600 mx-auto mt-4 md:mt-5"></div>
      </div>
      {closedCalls.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-10">
          {closedCalls.map((call) => (
            <JobCard
              key={call.slug.current}
              title={call.title}
              category="Closed"
              description={call.metaDescription || call.excerpt}
              imageUrl={call.mainImage?.asset?.url}
              slug={call.slug.current}
            />
          ))}
        </div>
      ) : (
        <div className="flex justify-center items-center min-h-[100px] text-center text-xl text-red-600 bg-gray-100 rounded-lg shadow-md mx-auto w-full max-w-3xl">
          <p>No closed calls available at the moment. Stay tuned for future opportunities!</p>
        </div>
      )}
    </div>
  );
};

export default ClosedCallsProject;