import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import sanityClient from '../../sanity';

const JobCard = ({ category, title, description, imageUrl, slug }) => {
  const navigate = useNavigate();

  const handleReadMore = () => {
    if (slug) {
      navigate(`/projects/${slug}`);
    }
  };

  return (
    <div className="flex flex-col bg-white rounded-3xl overflow-hidden shadow-lg w-full h-[300px] md:flex-row">
      <div className="w-full h-48 md:w-1/2 md:h-full relative overflow-hidden">
        {imageUrl ? (
          <img src={imageUrl} alt={title} className="w-full h-full object-cover object-center absolute inset-0" />
        ) : (
          <div className="w-full h-full flex items-center justify-center bg-gray-200 text-gray-500">No Image Available</div>
        )}
      </div>
      <div className="flex flex-col justify-between p-6 md:w-1/2">
        <div>
          <p className="text-red-600 text-lg text-left mb-2">{category}</p>
          <h3 className="font-bold text-xl text-left mb-4">{title}</h3>
          <p className="text-gray-600 text-base text-left overflow-hidden flex-grow mb-4">
            {description || 'No description available.'}
          </p>
        </div>
        <button
          className="text-red-600 text-lg bg-transparent border-none cursor-pointer p-0 text-left self-start"
          onClick={handleReadMore}
          disabled={!slug}
        >
          Read More
        </button>
      </div>
    </div>
  );
};

const OngoingCallsProject = () => {
  const [ongoingCalls, setOngoingCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "projectsPost" && category == "ongoing"]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          metaDescription
        }`
      )
      .then((data) => {
        if (data.length === 0) {
          setError('No ongoing calls found.');
        }
        setOngoingCalls(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Sanity fetch error:', err);
        setError('Failed to load ongoing calls.');
        setLoading(false);
      });
  }, []);

  if (loading) return <div className="text-center py-8">Loading ongoing calls...</div>;
  if (error) return <div className="text-center py-8 text-red-600">{error}</div>;

  return (
    <div className="bg-gray-100 p-8 bg-[url('/public/Asset 3.png')] bg-[length:300px_300px] bg-top bg-no-repeat bg-origin-content pt-20">
      <div className="text-center mb-4">
        <h2 className="text-gray-600 mb-2">JOIN THE FUTURE : ONGOING CALLS NOW AVAILABLE</h2>
        <h1 className="text-red-600 text-4xl font-bold">INVESTMENT OPPORTUNITIES AWAIT</h1>
        <div className="w-16 h-1 bg-red-600 mx-auto mt-5"></div>
      </div>
      {ongoingCalls.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          {ongoingCalls.map((call) => (
            <JobCard
              key={call.slug?.current || Math.random()}
              title={call.title || 'Untitled Project'}
              category="Ongoing"
              description={call.metaDescription}
              imageUrl={call.mainImage?.asset?.url}
              slug={call.slug?.current}
            />
          ))}
        </div>
      ) : (
        <div className="flex justify-center items-center min-h-[100px] text-center text-xl text-red-600 bg-gray-100 rounded-lg shadow-lg my-5 mx-auto w-full max-w-3xl">
          <p>No ongoing calls available at the moment. Stay tuned for future opportunities!</p>
        </div>
      )}
    </div>
  );
};

export default OngoingCallsProject;