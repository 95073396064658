import React from 'react';
import { motion } from 'framer-motion';

const AnimatedMemberCard = ({ name, index }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.5, rotateX: -180 }}
    animate={{ opacity: 1, scale: 1, rotateX: 0 }}
    transition={{
      type: "spring",
      stiffness: 260,
      damping: 20,
      delay: index * 0.1
    }}
    whileHover={{ scale: 1.05, boxShadow: "0 10px 20px rgba(0,0,0,0.1)" }}
    className="bg-white rounded-lg shadow-md p-6 flex items-center justify-center"
  >
    <span className="text-xl font-semibold text-gray-800 text-center">{name}</span>
  </motion.div>
);

const ResponsiveImagePage = () => {
  const boardMembers = [
    "Mr. Edmir Nufi",
    "Mr. Ami Kozeli",
    "Ms. Sonila Qato",
    "Ms. Alketa Pernaska",
    "Mr. Valery Tsepkalo"
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.h1 
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-4xl font-bold mb-8 text-center text-[#e41e26]"
      >
        INSTITUTIONAL STRUCTURE
      </motion.h1>
      <motion.div 
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="flex justify-center mb-12"
      >
        <img
          src="../organigrama.png"
          alt="Institutional Structure"
          className="w-full max-w-4xl h-auto object-cover rounded-lg shadow-lg"
        />
      </motion.div>
      <div className="max-w-3xl mx-auto">
        <motion.h2 
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-semibold mb-6 text-center text-[#e41e26]"
        >
          Advisory Board Members
        </motion.h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {boardMembers.map((member, index) => (
            <AnimatedMemberCard key={member} name={member} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResponsiveImagePage;