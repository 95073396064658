import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { Link } from 'react-router-dom';  // Add this import
import './Project.css';

const Project = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(2);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);

  const slides = [
    { title: '', color: 'purple', image: 'expo.jpg' },
    { title: '', color: 'green', image: 'real estate.png' },
  ];

  useEffect(() => {
    const handleResize = () => {
      const smallScreen = window.innerWidth <= 1024;
      setSlidesPerView(smallScreen ? (window.innerWidth <= 768 ? 1 : 2) : 2);
      setIsSmallScreen(smallScreen);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let interval;
    if (isSmallScreen && !isDragging) {
      interval = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % (slides.length - slidesPerView + 1));
      }, 3000); // Change slide every 3 seconds
    }
    return () => clearInterval(interval);
  }, [isSmallScreen, slides.length, slidesPerView, isDragging]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % (slides.length - slidesPerView + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + (slides.length - slidesPerView + 1)) % (slides.length - slidesPerView + 1));
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - e.currentTarget.offsetLeft;
    const walk = (x - startX) * 3; // Adjust this value to change sensitivity
    if (walk > 50) {
      prevSlide();
      setIsDragging(false);
    } else if (walk < -50) {
      nextSlide();
      setIsDragging(false);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <>
      <div className="project-container" style={{ backgroundImage: `url(sea.jpg)` }}>
        <div className="project-overlay"></div>
        <div className="project-content">
          <div className="project-red-accent"></div>
          <h1 className="ProjectsHomeAic-heading1">DISCOVER ALBANIA'S POTENTIAL</h1>
          <h2 className="ProjectsHomeAic-heading2">OPEN CALL TO INVEST IN OUR PROJECTS TODAY</h2>
          <p className="ProjectsHomeAic-paragraph">
            Unlock the immense opportunities Albania has to offer, from its untapped natural resources to its rapidly growing sectors in tourism, infrastructure, and renewable energy. Our projects are designed to propel Albania into the future, and we are inviting investors to be a part of this exciting journey. By investing today, you will join a forward-thinking community of innovators and entrepreneurs, ensuring sustainable growth and impactful returns.
          </p>

          <div className="project-slider" {...handlers}>
            {!isSmallScreen && <button onClick={prevSlide} className="project-arrow left">&#10094;</button>}
            <div 
              className="project-slides-container" 
              style={{ transform: `translateX(-${currentSlide * (100 / slidesPerView)}%)` }}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
            >
              {slides.map((project, index) => (
                <div key={index} className="project-slide">
                  <div 
                    className="project"
                    style={{ 
                      backgroundColor: project.color,
                      backgroundImage: `url('${project.image}')`,
                    }}
                  >
                    <h3>{project.title}</h3>
                  </div>
                </div>
              ))}
            </div>
            {!isSmallScreen && <button onClick={nextSlide} className="project-arrow right">&#10095;</button>}
          </div>
        </div>
      </div>

      {/* Investment CTA section outside of the main container */}
      <div className="project-investment-cta">
        <h3>YOUR INVESTMENT IS SUPPORTED BY AIC!</h3> 
        <Link to="/contact" className="project-invest-button">INVEST NOW</Link>
      </div>
    </>
  );
};

export default Project;
