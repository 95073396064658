import { useEffect } from 'react';

function useScrollToTop() {
  useEffect(() => {
    const handleRouteChange = () => {
      window.scrollTo(0, 0);
    };

    // Listen for popstate event (browser back/forward navigation)
    window.addEventListener('popstate', handleRouteChange);

    // Clean up the event listener
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);
}

export default useScrollToTop;