import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Clock, Mail, MapPin, Linkedin, Instagram, Facebook, Search, File, FolderOpen, Bell, Users, Network, ChevronDown, X, Twitter } from 'lucide-react';

const MainHeader = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [openMobileDropdown, setOpenMobileDropdown] = useState(null);

  const imageMapping = useMemo(() => ({
    '/about-us/vision-and-mission': '/sea.jpg',
    '/about-us/why-choose-aic': '/sea.jpg',
    '/about-us/laws-regulations': '/sea.jpg',
    '/projects': '/sea.jpg',
    '/open-calls/ongoing': '/sea.jpg',
    '/open-calls/closed': '/sea.jpg',
    '/team/institutional-structure': '/sea.jpg',
    '/team/meet-the-staff': '/sea.jpg',
    '/team/join-us': '/sea.jpg',
    '/networking/relationship-with-line-ministers': '/sea.jpg',
    '/networking/aida': '/sea.jpg',
    '/networking/adf': '/sea.jpg',
    '/contact': '/sea.jpg',
    '/news': '/news.jpg',
  }), []);

  const defaultImage = '/sea.jpg';
  const [selectedImage, setSelectedImage] = useState(defaultImage);

  const menuItems = [
    {
      title: 'ABOUT US',
      icon: File,
      submenu: [
        { title: 'Our Vision & Mission', link: '/about-us/vision-and-mission' },
        { title: 'Why Choose AIC', link: '/about-us/why-choose-aic' },
        { title: 'By Laws and Regulations', link: '/about-us/law-and-regulations' },
        { title: 'Transparency Program'},
      ],
    },
    { title: 'NEWS', link: '/news', icon: File, isClickable: true },
    { title: 'PROJECTS', link: '/projects', isClickable: true, icon: FolderOpen },
    {
      title: 'OPEN CALLS',
      icon: Bell,
      submenu: [
        { title: 'Ongoing', link: '/open-calls/ongoing' },
        { title: 'Closed Calls', link: '/open-calls/closed' },
      ],
    },
    {
      title: 'TEAM',
      icon: Users,
      submenu: [
        { title: 'Institutional Structure', link: '/team/institutional-structure' },
        { title: 'Meet the Staff', link: '/team/meet-the-staff' },
        { title: 'Join Us', link: '/team/join-us' },
      ],
    },
    {
      title: 'NETWORKING',
      icon: Network,
      submenu: [
        { title: 'Relationship with Line Ministers'},
        { title: 'Relationship with AIDA'},
        { title: 'Relationship with ADF'},
      ],
    },
    { title: 'CONTACT', link: '/contact', icon: Mail, isClickable: true },
  ];

  useEffect(() => {
    const currentPath = location.pathname;
    const isTeamMemberPage = currentPath.startsWith('/team/meet-the-staff/');
    
    let matchedImage;
    if (isTeamMemberPage) {
      matchedImage = imageMapping['/team/meet-the-staff'];
    } else {
      matchedImage = imageMapping[currentPath] || defaultImage;
    }
    
    setSelectedImage(matchedImage);
  }, [location.pathname, imageMapping]);

  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.innerWidth <= 1024;
      setIsMobile(isMobileView);
      if (!isMobileView) {
        setIsMenuOpen(false);
        document.body.style.overflow = 'auto';
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.style.overflow = 'auto';
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = isMenuOpen ? 'auto' : 'hidden';
  };

  const toggleMobileDropdown = (index, e) => {
    e.stopPropagation();
    setOpenMobileDropdown(openMobileDropdown === index ? null : index);
  };

  const isActiveMenu = (item) => {
    const currentPath = location.pathname;
    if (item.link === currentPath) return true;
    if (item.title === 'TEAM' && currentPath.startsWith('/team/')) return true;
    if (item.submenu) {
      return item.submenu.some(subItem => currentPath.startsWith(subItem.link));
    }
    return false;
  };

  const getCurrentPageTitle = () => {
    const currentPath = location.pathname;
    
    if (currentPath.startsWith('/team/meet-the-staff/')) {
      return 'TEAM MEMBER';
    }
    
    let pageTitle = '';
    menuItems.forEach((item) => {
      if (item.link === currentPath) {
        pageTitle = item.title;
      } else if (item.submenu) {
        item.submenu.forEach((subItem) => {
          if (subItem.link === currentPath) {
            pageTitle = subItem.title;
          }
        });
      }
    });
    
    if (!pageTitle) {
      pageTitle = currentPath.split('/').pop().replace(/-/g, ' ');
    }
    return pageTitle.toUpperCase();
  };

  const currentPageTitle = getCurrentPageTitle();

  return (
    <>
      <div className="relative w-full text-white font-rubik">
        <div className="absolute top-0 left-0 right-0 z-20">
          <div className="hidden lg:flex justify-end items-center py-2 px-5 text-sm">
            <div className="flex gap-5">
              <span className="flex items-center"><Clock className="w-4 h-4 mr-1" /> Mon - Fri : 08:00 - 17:00</span>
              <span className="flex items-center"><Mail className="w-4 h-4 mr-1" /> info@corporation.al</span>
              <span className="flex items-center"><MapPin className="w-4 h-4 mr-1" /> St. Ibrahim Rugova 5, Sky Tower 7/1, Tirana, Albania</span>
            </div>
            <button className="bg-red-600 text-white px-4 py-1 rounded-lg ml-5">INVEST NOW!</button>
          </div>

          <div className="flex justify-between items-center px-5">
            <div className="py-2">
              <Link to="/">
                <img src="/AIClogo.png" alt="Albanian Investment Corporation" className="h-24 w-72 object-contain" />
              </Link>
            </div>
            
            <nav className={`hidden lg:block ${isMenuOpen ? 'active' : ''}`}>
              <ul className="flex">
                {menuItems.map((item, index) => (
                  <li 
                    key={index} 
                    className={`relative group ${item.submenu ? 'has-submenu' : ''} ${isActiveMenu(item) ? 'active' : ''}`}
                  >
                    {item.isClickable || item.link ? (
                      <Link 
                        to={item.link} 
                        className={`px-4 py-2 block transition duration-300 ${isActiveMenu(item) ? 'bg-white/10' : ''}`}
                      >
                        {item.title}
                      </Link>
                    ) : (
                      <span className="px-4 py-2 block cursor-default">
                        {item.title}
                      </span>
                    )}
                    
                    {item.submenu && (
                      <div className="absolute top-full left-1/2 transform -translate-x-1/2 bg-red-600 rounded-md hidden group-hover:block w-[250px] shadow-lg z-50">
                        <ul className="py-2">
                          {item.submenu.map((subItem, subIndex) => (
                            <li key={subIndex} className="px-2 py-1">
                              <Link 
                                to={subItem.link} 
                                className="block px-3 py-2 hover:bg-white hover:text-red-600 transition duration-300 rounded-md"
                              >
                                {subItem.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </nav>

            {!isMenuOpen && (
              <div className="hidden lg:flex gap-2 mr-24">
                <a href="https://www.linkedin.com/posts/the-albanian-investment-corporation_rom%C3%AB-itali-n%C3%ABnshkruam-sot-marr%C3%ABveshjen-activity-7240402301532688384-Posp?utm_source=share&utm_medium=member_ios" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer"><Linkedin className="w-4 h-4" /></a>
                <a href="https://www.instagram.com/p/C_3UCS3tvyX/?igsh=MWY2MGxkdGl4b2NsMg==" aria-label="Instagram" target="_blank" rel="noopener noreferrer"><Instagram className="w-4 h-4" /></a>
                <a href="https://www.facebook.com/share/p/FT9ppzzRj1hs6Km3/?mibextid=WC7FNe" aria-label="Facebook" target="_blank" rel="noopener noreferrer"><Facebook className="w-4 h-4" /></a>
                <a href="https://x.com/albania_aic/status/1834634831642206698?s=46" aria-label="Twitter" target="_blank" rel="noopener noreferrer"><Twitter className="w-4 h-4" /></a>
              </div>
            )}
            
            <button 
              className="lg:hidden flex flex-col justify-center items-center w-8 h-6 space-y-1.5" 
              onClick={toggleMenu}
              aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
            >
              <span className="w-full h-0.5 bg-white"></span>
              <span className="w-full h-0.5 bg-white"></span>
              <span className="w-full h-0.5 bg-white"></span>
            </button>
          </div>
          <div className="border-b border-white mx-5"></div>
        </div>

        <div className="relative h-[600px] w-full overflow-hidden">
          <div className="absolute inset-0 bg-black/50 z-10"></div>
          <img src={selectedImage} alt="Header background" className="w-full h-full object-cover" />
          <div className="absolute top-[70%] left-12 transform -translate-y-1/2 z-20">
            <h1 className="text-5xl text-white capitalize m-0">{currentPageTitle}</h1>
            <p className="text-xl mt-2 text-white uppercase">{location.pathname}</p>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap justify-around items-stretch bg-red-600 min-h-[180px] -mt-20 relative z-10 px-2 sm:px-4 md:px-6 lg:px-24">
        {menuItems.map((item, index) => (
          <div
            key={index}
            className={`flex flex-col items-center justify-center text-white text-[10px] sm:text-xs md:text-sm transition-all duration-300 w-1/3 sm:w-1/4 md:flex-1 px-1 sm:px-2 md:px-3 py-2 sm:py-3 md:py-4 relative ${isActiveMenu(item) ? 'active-item' : ''}`}
          >
            <div className={`flex flex-col items-center justify-center w-full h-full ${isActiveMenu(item) ? 'bg-white rounded-xl sm:rounded-2xl px-1 sm:px-2 md:px-3 py-2 sm:py-3 md:py-4' : 'px-1 sm:px-2 md:px-3 py-2 sm:py-3 md:py-4'}`}>
              <div className="flex items-center justify-center w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 mb-1 sm:mb-2">
                <item.icon className={`w-4 h-4 sm:w-6 sm:h-6 md:w-8 md:h-8 lg:w-10 lg:h-10 ${isActiveMenu(item) ? 'text-red-600' : 'text-white'}`} />
              </div>
              <span className={`mt-1 text-center w-full truncate px-1 ${isActiveMenu(item) ? 'text-red-600' : 'text-white'}`}>
                {item.title}
              </span>
            </div>
            {index !== menuItems.length - 1 && (
              <div className="absolute right-0 top-1/2 -translate-y-1/2 h-4/5 w-[1px] bg-white/50" />
            )}
          </div>
        ))}
      </div>

     {/* Mobile Dropdown Menu (continued) */}
     {isMobile && (
        <div className={`fixed inset-0 bg-white z-50 transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
          <button className="absolute top-5 right-5 text-red-600 text-3xl" onClick={toggleMenu}>X</button>
          <div className="pt-20 px-5">
            <nav>
              <ul>
                {menuItems.map((item, index) => (
                  <li key={index}>
                    <div className="flex justify-between items-center">
                      {item.submenu ? (
                        <span className="text-red-600 font-bold py-2 block">{item.title}</span>
                      ) : (
                        <Link to={item.link} className="text-red-600 font-bold py-2 block" onClick={toggleMenu}>{item.title}</Link>
                      )}
                      {item.submenu && (
                        <button
                          className="p-2"
                          onClick={(e) => toggleMobileDropdown(index, e)}
                        >
                          <ChevronDown
                            className={`w-6 h-6 text-red-600 transform transition-transform duration-300 ${openMobileDropdown === index ? 'rotate-180' : ''}`}
                          />
                        </button>
                      )}
                    </div>
                    {item.submenu && (
                      <div className={`pl-5 ${openMobileDropdown === index ? 'block' : 'hidden'}`}>
                        <ul>
                          {item.submenu.map((subItem, subIndex) => (
                            <li key={subIndex}>
                              <Link to={subItem.link} onClick={toggleMenu} className="text-red-600 font-bold py-2 block">{subItem.title}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
            <div className="border-t border-red-600 my-5"></div>
            <div className="relative mb-5">
              <input type="text" placeholder="Search..." className="w-full p-2 border-2 border-red-600 rounded-md" />
              <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-red-600 w-5 h-5" />
            </div>
            <div className="flex gap-5">
            <a href="https://www.facebook.com/AlbanianInvestmentCorporation" aria-label="Facebook" target="_blank" rel="noopener noreferrer"><Facebook className="w-6 h-6 text-red-600" /></a>
            <a href="https://www.instagram.com/albanianinvestmentcorporation/" aria-label="Instagram" target="_blank" rel="noopener noreferrer"><Instagram className="w-6 h-6 text-red-600" /></a>
            <a href="https://www.linkedin.com/company/the-albanian-investment-corporation/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer"><Linkedin className="w-6 h-6 text-red-600" /></a>
            <a href="https://x.com/albania_aic/" aria-label="Twitter" target="_blank" rel="noopener noreferrer"><Twitter className="w-6 h-6 text-red-600" /></a>
          </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MainHeader;