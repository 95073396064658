import React, { useState, useEffect } from 'react';
import sanityClient from '../../sanity';

const NewsPage = () => {
  const [newsPosts, setNewsPosts] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "newsPost"] | order(publishDate desc) {
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          body,
          category,
          publishDate,
          "excerpt": pt::text(body)[0...200]
        }`
      )
      .then((data) => {
        const categorizedPosts = data.reduce((acc, post) => {
          if (!acc[post.category]) {
            acc[post.category] = [];
          }
          acc[post.category].push(post);
          return acc;
        }, {});
        setNewsPosts(categorizedPosts);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError("Failed to load news posts.");
        setLoading(false);
      });
  }, []);

  if (loading) return <div className="text-center py-10">Loading...</div>;
  if (error) return <div className="text-center py-10 text-red-600">{error}</div>;

  const categories = ['news', 'events', 'notices'];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 font-sans text-gray-800">
      {categories.map((category) => (       
        <NewsCategory 
          key={category} 
          category={category} 
          posts={newsPosts[category] || []}
          isMobile={isMobile}
        />
      ))}
    </div>
  );
};

const NewsCategory = ({ category, posts, isMobile }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const nextSlide = () => {
    if (isAnimating || currentIndex + 3 >= posts.length) return;
    setIsAnimating(true);
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 3, posts.length - 3));
    setTimeout(() => setIsAnimating(false), 500);
  };

  const prevSlide = () => {
    if (isAnimating || currentIndex === 0) return;
    setIsAnimating(true);
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 3, 0));
    setTimeout(() => setIsAnimating(false), 500);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const renderNewsItem = (post) => (
    <div key={post.slug.current} className="bg-white border border-gray-200 p-4 flex flex-col h-full transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
      <img
        src={post.mainImage?.asset?.url || 'https://via.placeholder.com/300x150'}
        alt={post.mainImage?.alt || 'News Image'}
        className="w-full h-[150px] object-cover mb-4"
      />
      <div className="flex flex-col flex-grow">
        {post.publishDate && (
          <p className="text-sm text-gray-500 mb-2">
            {formatDate(post.publishDate)}
          </p>
        )}
        <h3 className="text-lg font-semibold text-red-600 mb-2">{post.title}</h3>
        <div className="flex-grow overflow-hidden mb-4">
          <p className="text-sm text-gray-600">{post.excerpt}...</p>
        </div>
        <div className="mt-auto">
          <a href={`/news/${post.slug.current}`} className="inline-block text-sm font-bold text-red-600 hover:text-red-700 transition duration-300">
            Read More
          </a>
        </div>
      </div>
    </div>
  );

  if (isMobile) {
    return (
      <div className="mb-10">
        <h2 className="bg-red-600 text-white py-2 px-4 text-lg font-normal mb-4">
          {category.charAt(0).toUpperCase() + category.slice(1)}
        </h2>
        <div className="bg-gray-100 p-4">
          {posts.length > 0 ? (
            <>
              {renderNewsItem(posts[currentIndex])}
              {posts.length > 1 && (
                <div className="flex justify-between mt-4">
                  <button 
                    className="bg-red-600 text-white w-10 h-10 flex items-center justify-center disabled:bg-gray-400 disabled:cursor-not-allowed"
                    onClick={prevSlide} 
                    disabled={currentIndex === 0 || isAnimating}
                  >
                    ❮
                  </button>
                  <button 
                    className="bg-red-600 text-white w-10 h-10 flex items-center justify-center disabled:bg-gray-400 disabled:cursor-not-allowed"
                    onClick={nextSlide} 
                    disabled={currentIndex + 1 >= posts.length || isAnimating}
                  >
                    ❯
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className="text-gray-500 italic p-4">
              Currently, there's no content available for {category}.
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="mb-10 relative">
      <h2 className="bg-red-600 text-white py-2 px-4 text-lg font-normal mb-4">
        {category.charAt(0).toUpperCase() + category.slice(1)}
      </h2>
      <div className="bg-gray-100 p-4">
        {posts.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 auto-rows-fr">
            {posts.slice(currentIndex, currentIndex + 3).map(renderNewsItem)}
          </div>
        ) : (
          <div className="text-gray-500 italic p-4">
            Currently, there's no content available for {category}.
          </div>
        )}
      </div>
      {posts.length > 3 && (
        <>
          <button 
            className="absolute top-1/2 -left-5 transform -translate-y-1/2 bg-red-600 text-white w-10 h-10 flex items-center justify-center disabled:bg-gray-400 disabled:cursor-not-allowed"
            onClick={prevSlide} 
            disabled={currentIndex === 0 || isAnimating}
          >
            ❮
          </button>
          <button 
            className="absolute top-1/2 -right-5 transform -translate-y-1/2 bg-red-600 text-white w-10 h-10 flex items-center justify-center disabled:bg-gray-400 disabled:cursor-not-allowed"
            onClick={nextSlide} 
            disabled={currentIndex + 3 >= posts.length || isAnimating}
          >
            ❯
          </button>
        </>
      )}
    </div>
  );
};

export default NewsPage;