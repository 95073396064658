import React from 'react';
import { Link } from 'react-router-dom';
import './MeetTheStaff.css';

const teamMembers = [
  { name: 'ELIRA KOKONA', role: 'Executive Director', image: '../Elira.png', profileLink: '/team/meet-the-staff/elira-kokona' },
  { name: 'JULIAN ADILI', role: 'Chief Operations Officer', image: '../Juli.png', profileLink: '/team/meet-the-staff/julian-adili' },
  { name: 'VALENTINA KAZANXHI', role: 'Chief of Business Development', image: '../VALENTINA.png',profileLink: '/team/meet-the-staff/valentina-kazanxhi'},
  { name: 'VACANT', role: 'Chief Legal Officer', image: null, profileLink: null },
  { name: 'GLEDIS SHEHU', role: 'Chief Financial Officer', image: '../Gledi.png', profileLink: '/team/meet-the-staff/gledis-shehu'},
];

const TeamMemberCard = ({ name, role, image, profileLink }) => {
  const ImageContent = () => (
    image ? (
      <img src={image} alt={name} className="team-member-image" />
    ) : (
      <div className="team-placeholder-image">
        👤
      </div>
    )
  );

  return (
    <div className="team-member-card">
      <div className="team-card-content">
        <div className="team-image-container">
          {profileLink ? (
            <Link to={profileLink} aria-label={`View ${name}'s profile`}>
              <ImageContent />
            </Link>
          ) : (
            <ImageContent />
          )}
        </div>
        <h3 className="team-member-name">
          {name === 'VACANT' ? 'Position Available' : name}
        </h3>
        <p className="team-member-role">{role}</p>
      </div>
    </div>
  );
};

const TeamMembersShowcase = () => {
  return (
    <div className="team-members-container">
      <h2 className="team-subheading">OUR TEAM</h2>
      <h1 className="team-heading">MEET OUR DEDICATED TEAM</h1>
      <div className="team-members">
        <div className="team-member-container">
          {teamMembers.map((member) => (
            <TeamMemberCard key={member.name} {...member} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamMembersShowcase;
