import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import sanityClient from '../../sanity';

const NewsCard = ({ imageUrl, metaTitle, metaDescription, slug, publishDate }) => (
  <div className="flex flex-col bg-white rounded-3xl overflow-hidden shadow-lg w-full">
    <div className="relative h-64 md:h-56 lg:h-64">
      {imageUrl ? (
        <img src={imageUrl} alt={metaTitle} className="w-full h-full object-cover object-center" />
      ) : (
        <div className="w-full h-full bg-gray-200 flex items-center justify-center text-gray-500">No Image Available</div>
      )}
    </div>
    <div className="flex flex-col justify-between p-6 md:p-7 flex-grow">
      <div>
        <p className="text-red-600 text-lg md:text-base lg:text-lg mb-2">News</p>
        <h3 className="font-bold text-xl md:text-lg lg:text-xl mb-3 md:mb-2 lg:mb-3">{metaTitle}</h3>
        <p className="text-gray-600 text-sm mb-2">{new Date(publishDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}</p>
        <p className="text-gray-600 text-base md:hidden lg:block lg:text-sm mb-4">{metaDescription}</p>
      </div>
      <Link to={`/news/${slug}`} className="text-red-600 text-lg md:text-base lg:text-lg mt-auto">
        Read More
      </Link>
    </div>
  </div>
);

const News = () => {
  const [newsPosts, setNewsPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "newsPost" && category == "news"] | order(publishDate desc)[0...4]{
          metaTitle,
          slug,
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          metaDescription,
          publishDate
        }`
      )
      .then((data) => {
        setNewsPosts(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching news posts:', err);
        setError('Failed to load news posts.');
        setLoading(false);
      });
  }, []);

  if (loading) return <div className="text-center text-xl text-white bg-black bg-opacity-50 p-4 rounded-lg my-8">Loading news...</div>;
  if (error) return <div className="text-center text-xl text-white bg-black bg-opacity-50 p-4 rounded-lg my-8">{error}</div>;

  return (
    <div className="bg-gray-100 py-8 md:py-16 bg-cover bg-center relative" style={{backgroundImage: `url('news.jpg')`}}>
      <div className="absolute inset-0 bg-black bg-opacity-70"></div>
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-2">NEWS FROM ALBANIA INVESTMENT CORPORATION</h2>
          <p className="text-lg text-white mb-4">Making Headlines in the Investment World</p>
          <div className="w-16 h-1 bg-red-600 mx-auto mt-4"></div>
        </div>

        {newsPosts.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 max-w-6xl mx-auto">
            {newsPosts.map((post) => (
              <NewsCard
                key={post.slug.current}
                imageUrl={post.mainImage?.asset?.url}
                metaTitle={post.metaTitle}
                metaDescription={post.metaDescription}
                slug={post.slug.current}
                publishDate={post.publishDate}
              />
            ))}
          </div>
        ) : (
          <div className="flex justify-center items-center min-h-[100px] text-center text-xl text-red-600 bg-gray-100 bg-opacity-80 rounded-xl shadow-lg my-5 w-full max-w-5xl mx-auto p-6">
            <p>No news articles available at the moment. Check back soon for updates!</p>
          </div>
        )}

        <Link to="/news" className="block mx-auto mt-12 px-6 py-3 bg-red-600 text-white text-lg font-semibold rounded-lg hover:bg-red-700 transition duration-300 ease-in-out text-center max-w-[200px]">
          SEE MORE
        </Link>
      </div>
    </div>
  );
};

export default News;
