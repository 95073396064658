import React, { useState } from 'react';
import './Redbox.css';

const slides = [
  {
    title: "Albanian Investment Corporation (AIC)",
    description: "AIC was established by Law no. 71, of October 17, 2019 of the Parliament of Albania. AIC has one purpose alone: make growth happen in Albania. It constitutes a capability that can identify and revitalize underutilized public assets; prepare, and execute investment projects, and overall contribute to draw in foreign investment and contribute to a diversified and sustainable economic growth in the country.",
    image: "/news.jpg"
  },
  {
    title: "Doing Business in Albania",
    description: "Doing Business-Investing in Albania has been developed and prepared by Nexia Albania and Optima Legal & Financial with the support of RisiAlbania, a project of Swiss Agency for Development and Cooperation SDC, implemented by Helvetas",
    image: "/1-121.jpg",
    pdfUrl: "/Doing Business 2024_AIC_Final.pdf"
  },
  // Add more slides as needed
];

const LawSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div className="law-slider">
      <div className="law-slider__content">
        <div className="law-slider__text">
          <h2 className="law-slider__title">{slides[currentSlide].title}</h2>
          <p className="law-slider__description">{slides[currentSlide].description}</p>
          {slides[currentSlide].pdfUrl && (
            <a 
              href={slides[currentSlide].pdfUrl} 
              download 
              className="law-slider__download-button"
            >
              View PDF
            </a>
          )}
        </div>
        <div className="law-slider__image-container">
          <img className="law-slider__image" src={slides[currentSlide].image} alt={slides[currentSlide].title} />
        </div>
      </div>
      <div className="law-slider__nav">
        <button onClick={prevSlide} className="law-slider__nav-button law-slider__nav-button--prev">
          &#8249;
        </button>
        <button onClick={nextSlide} className="law-slider__nav-button law-slider__nav-button--next">
          &#8250;
        </button>
      </div>
    </div>
  );
};

export default LawSlider;
