import React, { useState, useEffect } from 'react';
import sanityClient from '../../sanity';

const CareersPage = () => {
  const [careerPosts, setCareerPosts] = useState([]);
  const [careerEnPosts, setCareerEnPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentCareerIndex, setCurrentCareerIndex] = useState(0);
  const [currentEnIndex, setCurrentEnIndex] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    experience: '',
    coverLetter: ''
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    Promise.all([
      sanityClient.fetch(
        `*[_type == "newsPost" && category == "careers"] | order(_createdAt desc) {
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          body,
          publishDate,
          "excerpt": pt::text(body)[0...200]
        }`
      ),
      sanityClient.fetch(
        `*[_type == "englishProjectPost" && category == "careers"] | order(publishDate desc) {
          title,
          slug,
          mainImage {
            asset->{
              _id,
              url
            },
            alt
          },
          body,
          publishDate,
          projectType,
          projectStatus,
          "excerpt": pt::text(body)[0...200]
        }`
      )
    ])
    .then(([careerData, careerEnData]) => {
      setCareerPosts(careerData);
      setCareerEnPosts(careerEnData);
      setLoading(false);
    })
    .catch((err) => {
      console.error(err);
      setError("Failed to load posts.");
      setLoading(false);
    });
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    // Add your form submission logic here
  };

  const nextSlide = (setIndex, postsLength) => {
    if (isAnimating || (isMobile ? currentCareerIndex + 1 >= postsLength : currentCareerIndex + 3 >= postsLength)) return;
    setIsAnimating(true);
    setIndex((prevIndex) => isMobile ? prevIndex + 1 : Math.min(prevIndex + 3, postsLength - 3));
    setTimeout(() => setIsAnimating(false), 500);
  };

  const prevSlide = (setIndex, postsLength) => {
    if (isAnimating || currentCareerIndex === 0) return;
    setIsAnimating(true);
    setIndex((prevIndex) => isMobile ? prevIndex - 1 : Math.max(prevIndex - 3, 0));
    setTimeout(() => setIsAnimating(false), 500);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const renderNewsItem = (post, isEnglish = false) => (
    <div key={post.slug.current} className="bg-white border border-gray-200 p-4 flex flex-col transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg h-[340px]">
      <img
        src={post.mainImage?.asset?.url || 'https://via.placeholder.com/300x150'}
        alt={post.mainImage?.alt || 'Career Image'}
        className="w-full h-[150px] object-cover mb-4"
      />
      <div className="flex flex-col flex-grow">
        {post.publishDate && (
          <p className="text-sm text-gray-500 mb-2">
            {formatDate(post.publishDate)}
          </p>
        )}
        <h3 className="text-lg font-semibold text-red-600 mb-2 line-clamp-2">{post.title}</h3>
        <p className="text-sm text-gray-600 flex-grow overflow-hidden line-clamp-3">{post.excerpt}</p>
        <div className="mt-auto pt-2">
          <a href={isEnglish ? `/news/${post.slug.current}` : `/news/${post.slug.current}`} className="text-right text-sm font-bold text-red-600 hover:text-red-700 transition duration-300">
            Read More
          </a>
        </div>
      </div>
    </div>
  );

  const renderSection = (title, posts, currentIndex, setIndex, isEnglish = false) => (
    <div className="mb-10 relative">
      <h2 className="bg-red-600 text-white py-2 px-4 text-lg font-normal mb-4">
        {title}
      </h2>
      <div className="bg-gray-100 p-4">
        {posts.length > 0 ? (
          isMobile ? (
            <>
              {renderNewsItem(posts[currentIndex], isEnglish)}
              {posts.length > 1 && (
                <div className="flex justify-between mt-4">
                  <button 
                    className="bg-red-600 text-white w-10 h-10 flex items-center justify-center disabled:bg-gray-400 disabled:cursor-not-allowed"
                    onClick={() => prevSlide(setIndex, posts.length)} 
                    disabled={currentIndex === 0 || isAnimating}
                  >
                    ❮
                  </button>
                  <button 
                    className="bg-red-600 text-white w-10 h-10 flex items-center justify-center disabled:bg-gray-400 disabled:cursor-not-allowed"
                    onClick={() => nextSlide(setIndex, posts.length)} 
                    disabled={currentIndex + 1 >= posts.length || isAnimating}
                  >
                    ❯
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {posts.slice(currentIndex, currentIndex + 3).map(post => renderNewsItem(post, isEnglish))}
            </div>
          )
        ) : (
          <div className="text-gray-500 italic p-4">
            Currently, there are no career opportunities available.
          </div>
        )}
      </div>
      {!isMobile && posts.length > 3 && (
        <>
          <button 
            className="absolute top-1/2 -left-5 transform -translate-y-1/2 bg-red-600 text-white w-10 h-10 flex items-center justify-center disabled:bg-gray-400 disabled:cursor-not-allowed"
            onClick={() => prevSlide(setIndex, posts.length)} 
            disabled={currentIndex === 0 || isAnimating}
          >
            ❮
          </button>
          <button 
            className="absolute top-1/2 -right-5 transform -translate-y-1/2 bg-red-600 text-white w-10 h-10 flex items-center justify-center disabled:bg-gray-400 disabled:cursor-not-allowed"
            onClick={() => nextSlide(setIndex, posts.length)} 
            disabled={currentIndex + 3 >= posts.length || isAnimating}
          >
            ❯
          </button>
        </>
      )}
    </div>
  );

  if (loading) return <div className="text-center py-10">Loading...</div>;
  if (error) return <div className="text-center py-10 text-red-600">{error}</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 font-sans text-gray-800">
      {renderSection("Careers", careerPosts, currentCareerIndex, setCurrentCareerIndex)}
      {renderSection("Careers EN", careerEnPosts, currentEnIndex, setCurrentEnIndex, true)}

      {/* Application Form Section */}
      <div className="max-w-3xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl">
        <h1 className="text-4xl font-bold mb-6 text-red-600 text-center">WORK WITH US!</h1>
        <div className="mb-8 text-gray-700 text-center">
          <p className="mb-4">
            AIC is in continuous search for talent in various fields, including engineers, architects, urban planners, financiers, lawyers, project managers, and technical experts, with the aim of realizing the Corporation's objectives.
          </p>
          <p className="mb-4">
            We invite you to send a resume and a letter of interest to <strong className="text-red-600">hr@aicorporation.al</strong>.
          </p>
          <p className="mb-4">
            If your experience aligns with AIC's needs, we will contact you to further advance your professional engagement within AIC. Employment at AIC is conducted according to the provisions of the Labor Code.
          </p>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
              required
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-gray-700 font-bold mb-2">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
              required
            />
          </div>
          <div>
            <label htmlFor="phone" className="block text-gray-700 font-bold mb-2">Phone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
              required
            />
          </div>
          <div>
            <label htmlFor="experience" className="block text-gray-700 font-bold mb-2">Relevant Experience</label>
            <textarea
              id="experience"
              name="experience"
              value={formData.experience}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
              rows="4"
              required
            ></textarea>
          </div>
          <div>
            <label htmlFor="coverLetter" className="block text-gray-700 font-bold mb-2">Cover Letter</label>
            <textarea
              id="coverLetter"
              name="coverLetter"
              value={formData.coverLetter}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
              rows="6"
              required
            ></textarea>
          </div>
          <div>
            <label className="block text-gray-700 font-bold mb-2">Upload Resume</label>
            <input type="file" className="w-full" />
          </div>
          <div>
            <label className="block text-gray-700 font-bold mb-2">Upload Cover Letter</label>
            <input type="file" className="w-full" />
          </div>
          <button type="submit" className="w-full mt-6 bg-red-600 text-white font-bold py-2 px-4 rounded-md hover:bg-red-700 transition duration-300">
            Submit Application
          </button>
        </form>
      </div>
    </div>
  );
};

export default CareersPage;